import React, { Component } from 'react';
import fetchData from '../../api/Host/Get';
import Avatar from '../../utils/Avatar';
import { Link } from 'react-router-dom';
import ImageTransition from '../../utils/ImageTransition';
import Snipper from '../Snipper';
import Pagination from '../../utils/Pagination';
import { ReactComponent as BadgeSVG } from '../../assets/svg/badge.svg';

import '../../assets/css/host/host.css';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class Host extends Component {
  state = {
    list: [],
    spinner: <Snipper/>,
    page: 1,
    limit: 12,
    size: 0,
    search: null,
    category: 'ID',
  }

  async componentDidMount() {
    try {
      const data = await fetchData({
        offset: (this.state.page * this.state.limit) - this.state.limit,
        search: this.state.search,
        category: this.state.category,
        limit: this.state.limit,
        order: 'desc',
      });
      this.setState({
        list: data.host,
        size: data.size,
        spinner: '',
      });
    } catch (error) {
      console.error("Erreur interne", error);
    }
  }

  handleScroll = () => {
    const offset = window.scrollX;
    if (offset > 50) {
      this.handleBurger(true);
    } else {
      this.handleBurger(false);
    }
  };

  displayNav = () => {
    const nav = document.querySelector('.nav');
    nav.classList.toggle('active');
  }

  toggleSidebar = () => {
    this.setState(prevState => {
      const newVisibility = !prevState.isVisible;
      return { isVisible: newVisibility };
    });

    const header = document.querySelector('.host .nav');
    if (header) {
      header.classList.toggle('active', !this.state.isVisible);
    }
  };

  handlePage = (page) => {
    if (this.state.page !== page) {
      this.setState({ 
        page: Number(page),
        list: '',
        spinner: <Snipper/>,
      }, () => {
        this.componentDidMount();
      });
    }
  }

  handleSearchHost = (event) => {
    this.setState({ 
      search: event.target.value,
      list: '',
      spinner: <Snipper/>,
      page: 1,
    }, () => {
      this.componentDidMount();
    });
  }

  handleChangeOnglet = (key, event) => {
    this.setState({ 
      category: key,
      list: '',
      spinner: <Snipper/>,
      page: 1,
    }, () => {
      this.componentDidMount();
    });
  }

  handleImageError = (event) => {
    event.target.src = process.env.REACT_APP_API_BASE+'img/banner/banner-official.jpg';
  }

  isValidDate(dateString) {
    const [day, month, year, time] = dateString.split(/[/\s:]/);
    const isoDateString = `20${year}-${month}-${day}T${time}:00`;
    const date = new Date(isoDateString);
    return date instanceof Date && !isNaN(date.getTime());
  }

  formatDate = (dateString) => {
    const [day, month, year, time] = dateString.split(/[/ ]/);
    return new Date(`20${year}-${month}-${day}T${time}:00`);
  };
  
  render() {
    const totalPages = Math.ceil(this.state.size / this.state.limit);

    return (
      <>
        <div className="host">

          {/* Nav */}
          
          <div className='burger' onClick={() => this.toggleSidebar()}>
            <i className="fa-solid fa-bars"></i>
          </div>

          <nav className='nav'>
            <div className='burger-close' onClick={() => this.toggleSidebar()}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            <ul>
              <li className={this.state.category === "ID" ? 'active' : null} onClick={() => this.handleChangeOnglet("ID")}>Aucun</li>
              <li className={this.state.category === "PLAY" ? 'active' : null} onClick={() => this.handleChangeOnglet("PLAY")}>Les plus joués</li>
              <li className={this.state.category === "LIKES" ? 'active' : null} onClick={() => this.handleChangeOnglet("LIKES")}>Les plus aimés</li>
              <li className={this.state.category === "DATE_CREATE" ? 'active' : null} onClick={() => this.handleChangeOnglet("DATE_CREATE")}>Les plus récents</li>
              <li className={this.state.category === "TAGS" ? 'active' : null} onClick={() => this.handleChangeOnglet("TAGS")}>Tags</li>

              <div className='host-search'>
                <div>
                  <input type="text" value={this.state.search} onInput={this.handleSearchHost} placeholder="Rechercher un host" required/>
                </div>
                <div>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </ul>
          </nav>

          {/* Host info */}

          <div class="host-info">
						<h2>HOST FIREBLOCK</h2>
            <h3>Découvrez notre bibliothèque de maps pour FIREBLOCK</h3>
						<p>Le projet Host est une application pour concevoir un mode de jeu sur mesure. Elle permet aux créateurs de construire sa carte en jeu, de développer ses règles et son propre mode de jeu à partir de l'interface web NodeSK (similaire à Scratch).</p>
			      <p>Rejoignez notre communauté <a href="https://discord.com/invite/sGeB8Yc" target='blank'>Discord</a> <i class="fa-brands fa-discord"></i> Échangez avec d'autres créateurs, restez connecté sur nos mises à jour, apprenez à concevoir un mode de jeu et devez le premier Hoster de FIREBLOCK.</p>
						{/* <p>
              <i class="fa-solid fa-circle-info"></i> Comment importer un host en jeu ? <Link to="/host">Cliquez ici</Link>
              <i class="fa-solid fa-circle-info"></i> Comment créer un host ? <Link to="/host">Cliquez ici</Link>
            </p> */}
					</div>

          {/* Page */}

          <Pagination
            totalPages={totalPages}
            currentPage={this.state.page}
            onPageChange={this.handlePage}
          />

          {/* Dispaly Host */}

          <div className="host-display">
            {this.state.spinner}
            {this.state.list && this.state.list.map((data, index) => (
              <div className="host-content">

                <span className='theme'>{data.THEME}</span>
                
                <div className="head">
                  <Link to={"/host/" + data.GAME}>
                    <ImageTransition
                      src={`${process.env.REACT_APP_API_BASE}img/host/${data.GAME}/${data.GAME}.jpg`}
                      onError={this.handleImageError}
                      alt={data.GAME}
                    />
                  </Link>
                </div>
                
                <div className='info'>

                  {/* Left */}
                  <div className='left'>
                    <div className='sub'>
                      <Avatar name={data.AUTHOR}/>
                    </div>
                  </div>

                  {/* Right */}
                  <div className='right'>
                    <div className='sub'>
                      <div className='url'>
                        <Link to={"/host/" + data.GAME}>
                          {data.CATEGORY}
                        </Link>
                        <span className='author'>{data.AUTHOR}</span>
                      </div>

                      <div className='badge'>
                        <BadgeSVG class='tooltip-btn top' data-tooltip="Top 1"/>
                        <BadgeSVG class='tooltip-btn certify' data-tooltip="Certifié"/>
                        <BadgeSVG class='tooltip-btn origin' data-tooltip="Créativité"/>

                        <div class="tooltip" id="tooltip-top">Top 1</div>
                        <div class="tooltip" id="tooltip-certify">Certifié</div>
                        <div class="tooltip" id="tooltip-origin">Créativité</div>
                      </div>
                    </div>

                    <div className='sub'>
                      <span className='date'>
                      {data.LIKESTOTAL} <span>likes ▪ </span>
                      {this.isValidDate(data.DATE_CREATE) ? (
                        <ReactTimeAgo date={this.formatDate(data.DATE_CREATE)} locale="fr-FR"/>
                      ) : (
                        <span>Date invalide</span>
                      )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Page */}

          <Pagination 
            totalPages={totalPages} 
            currentPage={this.state.page} 
            onPageChange={this.handlePage} 
          />
        </div>
      </>
    )
  }
}
  
export default Host;