import API from '../../utils/Axios';
import Swal from 'sweetalert2';
import handleApiError from '../../utils/handleApiError';

const Login = async (email, password, callback) => {
  try {
    const { data } = await API().post(`/login`, { email, password });
    
    if (callback) {
      Swal.fire({
        icon: "success",
        text: data.message,
      }).then(() => {
        callback(data);
      });
    }

    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default Login;