import React, { Component } from 'react';
import '../../assets/css/utils/skeleton/skeletonTopic.css';

class Skeleton extends Component {
  render() {
    const { id } = this.props;

    return (
      <div className={`skeleton ${id ? id : ''}`}>
        <div className="skeleton-text"></div>
        <div className="skeleton-info"></div>
        <div className="skeleton-icon"></div>
      </div>
    )
  }
};

export default Skeleton;