import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import addData from '../../api/Ticket/Add';
import getData from '../../api/Ticket/GetCategory';
import Editor from '../../utils/Editor/Editor.js';
import withRouter from '../../utils/withRouter';
import Swal from 'sweetalert2';

import '../../assets/css/ticket/ticket-add.css';

class Ticket extends Component {
  state = {
    title: '',
    message: '',
    categorys: [],
    category: 1,
    showCategory: false,
  };

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      try {
        const data = await getData();
        this.setState({ categorys: data.datas });
      } catch (error) {
        console.error("Erreur interne", error);
      }
    }
  }

  handleSubmit = async () => {
    if (localStorage.getItem("token")) {
      const { category, title, message } = this.state;

      try {
        const data = await addData({ category, title, message });
        Swal.fire({
          icon: "success",
          text: data.message,
        }).then(() => {
          this.props.navigate(`/support/${data.datas.id}`);
        });
      } catch (error) {
        console.error("Erreur interne", error);
      }
    }
  };

  handleChangeTitle = (event) => {
    if (localStorage.getItem("token")) {
      this.setState({ title: event.target.value });
    }
  };

  handleChangeMessage = (message) => {
    if (localStorage.getItem("token")) {
      this.setState({ message });
    }
  };

  handleCategoryChange = (category) => {
    if (localStorage.getItem("token")) {
      this.setState({ category, showCategory: false });
    }
  };

  toggleCategory = () => {
    if (localStorage.getItem("token")) {
      this.setState((prevState) => ({ showCategory: !prevState.showCategory }));
    }
  };

  render() {
    const { categorys, title, message, category, showCategory } = this.state;
    const selectedCategory = categorys.find((c) => c.id === category);

    return (
      <div className="ticket-add">
        {/* Infos */}
        <div className="info">
          <h2>Tickets</h2>
          <h3>Ouvrir un nouveau ticket de support</h3>
          <p>Merci d'indiquer précisément la raison de votre signalement, indiquez le maximum de détails possibles.</p>
        </div>

        {/* Ticket */}
        <div className={`ticket-global ${!localStorage.getItem("token") ? 'none' : ''}`}>
          <div className="ticket-content">

            {/* Category */}
            <div className="input">
              <label>Catégorie</label>
              <div className="custom-dropdown">
                <button className="dropdown-btn" onClick={this.toggleCategory}>
                  {selectedCategory ? selectedCategory.title : "Sélectionner une catégorie"}
                </button>

                {showCategory && (
                  <ul className="dropdown-menu">
                    {categorys.map((data) => (
                      <li
                        key={data.id}
                        className={`option ${category === data.id ? "selected" : ""}`}
                        onClick={() => this.handleCategoryChange(data.id)}
                      >
                        {data.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            {/* Objet */}
            <div className='input'>
              <label>Objet</label>
              <input
                type="text"
                value={title}
                onChange={this.handleChangeTitle}
                placeholder="Objet"
              />
            </div>

            {/* Editor */}
            {localStorage.getItem("token") ? (
              <div className='reply'>
                <label>Message</label>
                <Editor
                  value={message}
                  onChange={this.handleChangeMessage}
                  onSubmit={this.handleSubmit}
                />
              </div>
            ) : (
              <div className='reply'>
                <label>Message</label>
                <Editor
                  value={message}
                  onChange={this.handleChangeMessage}
                  disabled={true}
                />
              </div>
            )}
          </div>
        </div>

        {/* Connect */}
        {!localStorage.getItem("token") && (
          <Link to="/login">
            <div className="connect">
              Vous devez vous connecter pour créer un ticket.
            </div>
          </Link>
        )}
      </div>
    );
  }
}

export default withRouter(Ticket);
