import React, { Component } from 'react';
// import { displayName } from 'react-quill';

class Avatar extends Component {
    state = {
        avatarUrl: null,
        loading: true,
        className: this.props.className || '',
    };

    componentDidMount() {
        this.loadAvatar();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.name !== this.props.name) {
            this.loadAvatar();
        }
    }

    loadAvatar = async () => {
        const { name } = this.props;
        const defaultAvatar = process.env.REACT_APP_API_BASE+"img/avatar/steve.png";

        this.setState({ loading: true });

        try {
            const imageUrl = `${process.env.REACT_APP_API_BASE}img/avatar/${name}.png`;
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => this.setState({ avatarUrl: imageUrl, loading: false });
            img.onerror = () => this.setState({ avatarUrl: defaultAvatar, loading: false });
        } catch (error) {
            this.setState({ avatarUrl: defaultAvatar, loading: false });
        }
    };

    render() {
        const { avatarUrl, loading, className } = this.state;
        const { name, width, height, borderRadius } = this.props;

        if (loading) {
            // return <div>Loading...</div>;
            return <img 
                // alt={`${name}'s avatar`}
                className={`avatar ${className}`}
                style={{
                    width: width ? width : '45px',
                    height: height ? height : '45px',
                    borderRadius: borderRadius ? borderRadius : '5px',
                    objectFit: 'cover',
                }}
            ></img>;
        }

        return <img
                src={avatarUrl}
                // alt={`${name}'s avatar`}
                className={`avatar ${className}`}
                style={{ 
                    width: width ? width : '45px',
                    height: height ? height : '45px',
                    borderRadius: borderRadius ? borderRadius : '5px',
                    objectFit: 'cover',
                    border: '1px solid rgba(200, 200, 200, 0)',
                }}
        />;
    }
}

export default Avatar;