import * as React from "react";

import Register from "../../components/Auth/Register";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

function Dashboard() {
  return (
    <>
      <Header nav="register"/>
      <div className="main">
        <Register/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
