import * as React from "react";

import VPN from "../../utils/VPN";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

function Dashboard() {
  return (
    <>
      <Header/>
      <VPN/>
      <Footer/>
    </>
  );
}

export default Dashboard;
