import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const GetDatas = async ({ offset, search, category, limit, order = "desc", statut }, callback) => {
  try {
    const { data } = await API(false).post(`/ticket/get`, {
        offset: offset,
        search: search,
        category: category,
        limit: limit,
        order: order,
        statut: statut
    });

    if (callback) {
      callback(data);
    }

    return data;
  } catch (error) {
    handleApiError(error, false);
  }
};

export default GetDatas;