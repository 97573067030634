import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import loginDatas from '../../api/Auth/Auth';
import '../../assets/css/auth/login.css';
import '../../assets/css/utils/animate-background.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      redirectToHome: false,
    };
  }

  componentDidMount() {
    this.handleSubmit();
  }

  handleSubmit = () => {
    const { token } = this.state;

    loginDatas(token, (data) => {
      localStorage.setItem("token", token);
      localStorage.setItem("player", data.player);
      this.setState({ redirectToHome: true });
    });
  }

  render() {
    const { redirectToHome } = this.state;

    if (redirectToHome) {
      return <Navigate to="/" />;
    }

    return (
      <>
        <div className="animate-background">
            <section>
              {[...Array(260)].map(() => <span></span>)}
            </section>
        </div>
      </>
    );
  }
}

export default Login;
