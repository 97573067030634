import * as React from "react";

import Vote from "../components/Vote";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Slider from '../utils/Slider';

function Dashboard() {
  return (
    <>
      <Header nav="vote"/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <Vote/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
