import React, { Component } from "react";
import ReactQuill from "react-quill";
import { Link } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "../../assets/css/utils/editor.css";

class Editor extends Component {
  constructor(props) {
    super(props);
    this.quillRef = React.createRef();
    this.state = {
      theme: 'snow',
      editorHtml: '',
      placeholder: 'Insérez votre message...',
      value: '',
      disabled: this.props.disabled || false,
    };

    this.handleThemeChange = this.handleThemeChange.bind(this);
  }

  componentDidMount() {
    if (this.quillRef.current) {
      // console.log("Quill instance initialisée :", this.quillRef.current.getEditor());
    }
  }

  handleImageUpload = () => {
    if (!this.state.disabled) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
  
      input.onchange = async () => {
        const file = input.files[0];
        // console.log("Fichier sélectionné:", file);
        if (!file) return;
  
        const formData = new FormData();
        formData.append("image", file);
        // console.log("FormData:", formData);
  
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload/image`, formData, {
            headers: { 
              "Content-Type": "multipart/form-data",
              "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
            },
          });
  
          const quill = this.quillRef.current?.getEditor();
          if (quill) {
            const range = quill.getSelection();
            // console.log("range", range);
  
            if (range && range.index !== null) {
              quill.insertEmbed(range.index, "image", response.data.url);
              quill.setSelection(range.index + 1);
            } else {
              const length = quill.getLength();
              quill.insertEmbed(length, "image", response.data.url);
              quill.setSelection(length + 1);
            }
          }
        } catch (error) {
          // console.error("Erreur lors de l'upload de l'image :", error);

          let errorMessage = "Une erreur interne s'est produite.";
          if (error.response && error.response.data) {
            errorMessage = error.response.data.error || JSON.stringify(error.response.data, null, 2);
          }
  
          Swal.fire({
            icon: "error",
            title: "Échec de l'upload",
            text: errorMessage,
          });
        }
      };
    }
  };

  handleChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleThemeChange = (newTheme) => {
    this.setState({ theme: newTheme === "snow" || newTheme === "bubble" ? newTheme : "snow" });
  };
  

  render() {
    const modules = {
      toolbar: {
        container: Editor.container,
        handlers: {
          image: this.handleImageUpload,
        },
      },
    };

    const { theme, value, placeholder, disabled } = this.state;
    const { cancel } = this.props;

    return (
      <div className="editor-container" style={{ position: "relative" }}>
        <ReactQuill
          className={`${theme} ${disabled ? 'disabled' : ''}`}
          key={theme}
          ref={this.quillRef}
          onChange={this.handleChange}
          theme={theme}
          value={this.props.placeholder || value}
          placeholder={disabled ? "" : placeholder}
          modules={modules}
          bounds={'.app'}
          readOnly={disabled}
        />

        {/* Actions */}

        <div className="actions">

          {/* Theme */}

          <div className="themes">
            <label>Theme </label>
            <select onChange={(e) => this.handleThemeChange(e.target.value)}>
              <option value="snow">Snow</option>
              <option value="bubble">Bubble</option>
            </select>
          </div>

          {/* Submit */}

          {!value || value === '' || value === '<p><br></p>' ? (
            <>
              {cancel && (
                <div className="sumbit" onClick={cancel}>
                  <i class="fa-solid fa-xmark"></i>
                  <button type="submit">Annuler</button>
                </div>
              )}
              <div className="sumbit disabled">
                <i class="fa-solid fa-paper-plane"></i>
                <button type="submit" disabled={true}>Envoyer</button>
                {}
              </div>
            </>
          ) : (
            <>
              {cancel && (
                <div className="sumbit" onClick={cancel}>
                  <i class="fa-solid fa-xmark"></i>
                  <button type="submit">Annuler</button>
                </div>
              )}
              <div className="sumbit" onClick={() => { this.props.onSubmit(); this.handleChange(''); }}>
                <i class="fa-solid fa-paper-plane"></i>
                <button type="submit">Envoyer</button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

// Editor

const Quill = ReactQuill.Quill;
var Font = Quill.import('formats/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto', "IBM Plex Sans"];

Editor.container = [
  [{ 'header': '1'}, {'header': '2'}, { 'font': Font.whitelist }],
  [{size: []}],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{'list': 'ordered'}, {'list': 'bullet'}, 
  {'indent': '-1'}, {'indent': '+1'}],
  ['link', 'image', 'video'],
  ['clean']
];

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default Editor;
