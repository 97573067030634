import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Avatar from '../../utils/Avatar';
import { Link } from 'react-router-dom';
import Editor from '../../utils/Editor/Editor.js';
import EditorShow from '../../utils/Editor/EditorShow.js';
import SkeletonPost from '../../utils/Skeleton/SkeletonPost';
import SkeletonBreadcrumb from '../../utils/Skeleton/SkeletonBreadcrumb';
import fetchData from '../../api/Forum/GetPost';
import addData from '../../api/Forum/AddMessage';
import delData from '../../api/Forum/DelMessage';
import editData from '../../api/Forum/EditMessage';

import Overlay from "../../utils/Overlay";
import overlayData from "../../assets/data/overlay.json";

import '../../assets/css/forum/forum-post.css';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class Forum extends Component {
  state = {
    posts: null,
    contentPost: '',
    contentEdit: '',
    editID: null,

    overlayVisible: false,
    overlayTitle: "",
    overlayContent: "",
    overlayActions: {},
    overlayDatas: {},
  }

  async componentDidMount() {
    const { forum, topic, post } = this.props;
    try {
      const data = await fetchData(forum, topic, post);
      this.setState({ 
        posts: data.post,
      });
    } catch (error) {
      console.error("Erreur interne", error);
    }
  }

  handleChangeContentPost = (content) => {
    this.setState({ contentPost: content });
  }

  handleChangeContentEdit = (content) => {
    this.setState({ contentEdit: content });
  }

  handleSubmit = async () => {
    const { forum, topic, post } = this.props;
    const { contentPost } = this.state;

    try {
        const data = await addData(forum, topic, post, contentPost);
        await this.componentDidMount();
        Swal.fire({
            icon: "success",
            text: data.message,
        });
    } catch (error) {
        console.error("Erreur interne", error);
    }
  };

  handleDelete = async (id) => {
    try {
        const data = await delData(id);
        await this.componentDidMount();
        Swal.fire({
            icon: "success",
            text: data.message,
        });
    } catch (error) {
        console.error("Erreur interne", error);
    }
  };

  handleEdit = async (id, message) => {
    this.setState({ 
      contentEdit: message,
      editID: id
    });
  };

  handleUpdate = async (id) => {
    const { contentEdit } = this.state;

    try {
        const data = await editData(id, contentEdit);
        this.setState({ 
          contentEdit: '',
          editID: null
        });
        await this.componentDidMount();
        Swal.fire({
            icon: "success",
            text: data.message,
        });
    } catch (error) {
        console.error("Erreur interne", error);
    }
  };

  toggleOverlay = (id, datas = null) => {
    if (!id || !overlayData[id]) return;

    this.setState({
      overlayVisible: !this.state.overlayVisible,
      overlayTitle: overlayData[id].title,
      overlayContent: overlayData[id].description,
      overlayActions: overlayData[id].action,
      overlayDatas: datas,
    });
  };

  render() {
    const { forum, topic, post } = this.props;
    const { overlayVisible, overlayTitle, overlayContent, overlayActions, overlayDatas } = this.state;
    const { posts, editID } = this.state;

    let i = 1;

    let skeletonCount = 10;

    return (
      <>
        {/* Overlay */}

        <Overlay
          visible={overlayVisible}
          title={overlayTitle}
          content={overlayContent}
          actions={overlayActions}
          onClose={() => this.setState({ overlayVisible: false })}
          datas={overlayDatas}
        />

        {/* Post */}

        {posts ? (
          <div className='post'>

            {/* Breadcrumb */}

            <div className='breadcrumb'>
              <Link to="/forum">Forum</Link> <i class="fa-solid fa-chevron-right"></i>
              <Link to={`/forum/${forum}/${topic}`}>{posts.topic.title}</Link> <i class="fa-solid fa-chevron-right"></i>
              <Link className='current' o={`/forum/${forum}/${topic}/${post}`}>{posts.title}</Link>
            </div>

            {/* Infos */}

            <h2>{posts.title}</h2>
            <div className="post-global">

              {/* Post author */}

              <div className='post-author'>
                <div className="post-item">
                  {posts.user && (
                    <>
                      <div className="author">
                        <Avatar name={posts.user.name} />
                        <p className='pseudo'>{posts.user.name}</p>
                      </div>
                      <div className="message">
                        <EditorShow data={posts.content}/>
                      </div>
                    </>
                  )}
                </div>
              </div>
              
              {/* Post messages */}

              <div className="messages">
                {posts.messages && posts.messages.map(message => (
                  <div className="post-item">
                    <div className="author">
                      <Avatar name={message.user.name} />
                      <p className='pseudo'>{message.user.name}</p>
                    </div>
                    <div className="message">
                      {editID == message.id ? (
                        <>
                          <Editor
                            value={this.state.contentEdit}
                            placeholder={this.state.contentEdit}
                            onChange={this.handleChangeContentEdit}
                            onSubmit={() => this.handleUpdate(message.id)}
                            cancel={() => this.handleEdit(null)}
                          />
                        </>
                      ) : (
                        <>
                        <div className='icons'>
                          {message.users_id == localStorage.getItem("id") ? (
                            <>
                              <i className="fa-solid fa-trash" onClick={() => this.handleDelete(message.id)}></i>
                              <i className="fa-solid fa-pen-to-square" onClick={() => this.handleEdit(message.id, message.message)}></i>
                            </>
                          ) : (
                            <i className="fa-solid fa-flag"></i>
                          )}
                        </div>
                        <EditorShow data={message.message}/>
                        </>
                      )}

                      <div className='date'>
                        <i className="fa-solid fa-hashtag">{i++}</i>
                        <button onClick={() => this.toggleOverlay("share", {"copy":`${process.env.REACT_APP_FRONT_URL}/forum/${forum}/${topic}/${post}`})}>
                          <i className="fa-solid fa-share-nodes"></i>
                        </button>
                        <small><ReactTimeAgo date={message.created_at} locale="fr-FR"/></small>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Post reply */}

              {localStorage.getItem("token") ? (
                <div className='reply'>
                  <Editor
                    value={this.state.contentPost}
                    onChange={this.handleChangeContentPost}
                    onSubmit={this.handleSubmit}
                  />
                </div>
              ) : (
                <div className='reply'>
                  <Editor
                    value={this.state.contentPost}
                    onChange={this.handleChangeContentPost}
                    disabled={true}
                  />

                  <Link to="/login">
                    <div className="connect">
                      Vous devez vous connecter pour répondre à ce post.
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='post'>

            {/* Breadcrumb */}

            <div className='breadcrumb'>
              <SkeletonBreadcrumb id="breadcrumb"/>
            </div>

            {/* Post author */}

            <div className='post-author'>
              <div className="post-item">
                <SkeletonPost id="post"/>
              </div>
            </div>

            {/* Post messages */}

            <div className="messages">
              {Array.from({ length: skeletonCount }).map(() => (
                <SkeletonPost id="post"/>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Forum;