import { useNavigate } from 'react-router-dom';
import API from '../../utils/Axios';
import Swal from 'sweetalert2'

const Logout = () => {
  const navigate = useNavigate();

  API()
    .post(`/logout`, { })
    .then(({data}) => {
      localStorage.removeItem("token");
      localStorage.removeItem("player");
      navigate("/");
      Swal.fire({
        icon: "success",
        text: data.message,
      })
    }).catch(({ response: { data } }) => {
      Swal.fire({
          icon: "error",
          text: data.message || "Une erreur interne s'est produite.",
      })
  })
}

export default Logout;
