import React from 'react';
import DOMPurify from 'dompurify';
import '../../assets/css/utils/editor-show.css';

const EditorShow = ({ data }) => {
    const sanitizedData = DOMPurify.sanitize(data);

    return (
        <div className='editor-show'>
            <span dangerouslySetInnerHTML={{ __html: sanitizedData }} />
        </div>
    );
};

export default EditorShow;
