import * as React from "react";

import NotFound from "../../utils/NotFound";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Slider from '../../utils/Slider';

function Dashboard() {
  return (
    <>
      <Header/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <NotFound/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
