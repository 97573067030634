import * as React from "react";

import { useParams } from 'react-router-dom';

import Auth from "../../components/Auth/Auth";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

function Dashboard() {
  let { token } = useParams();

  return (
    <>
      <Header nav="login"/>
      <div className="main">
        <Auth token={token}/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
