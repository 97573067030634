import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tooltip from "./Tooltip";
import "../assets/css/utils/overlay.css";

class Overlay extends Component {
    state = {
        tooltipText: "",
    }

    copyToClipboard = (text, actionText) => {
        navigator.clipboard.writeText(text);
        this.setState({ tooltipText: "Copié !" });
        setTimeout(() => {
            this.setState({ tooltipText: actionText });
        }, 1000);
    };

    render() {
        const { visible, title, content, actions, onClose, datas } = this.props;
        const { tooltipText } = this.state;

        if (!visible) return null;

        return (
            <div className="overlay" onClick={onClose}>
                <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                    <h3>{title} <i className="fa-solid fa-xmark" onClick={onClose}></i></h3>
                    <p>{content}</p>

                    <div className="overlay-actions">
                        {actions &&
                            Object.keys(actions).map((key) => {
                                const action = actions[key];

                                return action.type === "url" ? (
                                    <div key={key} className={action.type}>
                                        <Link
                                            to={action.url === "close" ? "#" : action.url}
                                            className={`btn ${action.class}`}
                                            onClick={(e) => {
                                                if (action.url === "close") {
                                                    e.preventDefault();
                                                    onClose();
                                                }
                                            }}
                                        >
                                            {action.title}
                                        </Link>
                                    </div>
                                ) : (
                                    <div key={key} className={action.type} style={{ position: "relative" }}>
                                        <input
                                            onClick={() => this.copyToClipboard(datas.copy, action.text)}
                                            value={datas.copy}
                                            disabled
                                        />
                                        <Tooltip text={tooltipText || action.text} position="top">
                                            <i
                                                className="fa-solid fa-copy"
                                                onClick={() => this.copyToClipboard(datas.copy, action.text)}
                                            ></i>
                                        </Tooltip>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Overlay;
