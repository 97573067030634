import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const AddDatas = async (forum, topic, post, message) => {
  try {
    const { data } = await API().post(`/forum/message`, { 
      forum_id: forum,
      topic_id: topic,
      post_id: post,
      message: message
    });
    
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default AddDatas;
