import * as React from "react";
import { useParams } from 'react-router-dom';

import QRCode from "../components/QRCode";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Slider from '../utils/Slider';

function Dashboard() {
  const { name, secret } = useParams();

  return (
    <>
      <Header nav="qrcode"/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <QRCode name={name} secret={secret}/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
