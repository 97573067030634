import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const GetDatas = async ({ offset, search, category, limit, order = "desc" }, callback) => {
  try {
    const { data } = await API().post(`/host/get`, { 
        offset: offset,
        search: search,
        category: category,
        limit: limit,
        order: order
    });

    if (callback) {
      callback(data);
    }

    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default GetDatas;