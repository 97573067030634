import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const Register = async (name, email, password, callback) => {
    try {
        await API().post(`/register`, { name, email, password });
        if (callback) {
            callback(callback);
        };
    } catch (error) {
        handleApiError(error);
    }
};

export default Register;