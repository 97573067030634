import React, { useState, useEffect } from 'react';

const ImageTransition = ({ src, alt, onError }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <img
        className={isVisible ? 'image-visible' : 'image'}
        src={src}
        alt={alt}
        onError={onError}
    />
  );
};

export default ImageTransition;