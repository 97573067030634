import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const AddDatas = async ({ id, message }, callback) => {
  try {
    const { data } = await API().post(`/ticket-message`, {
      ticket_id: id,
      message: message,
    });

    if (callback) {
      callback(data);
    }

    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default AddDatas;