import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import insertDatas from '../../api/Auth/Register';
import '../../assets/css/auth/login.css';
import '../../assets/css/utils/animate-background.css';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      redirectToHome: false,
    };
  }

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  }

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, password } = this.state;

    insertDatas(name, email, password, () => {
      this.setState({ redirectToHome: true });
    });
  }

  render() {
    const { name, email, password, redirectToHome } = this.state;

    if (redirectToHome) {
      return <Navigate to="/" />;
    }

    return (
      <>
        <div className="animate-background">
            <section>
              {[...Array(260)].map(() => <span></span>)}
              <div className='login'>
                  <div className="signin">
                      <div className="content">
                          <h2>Inscription</h2>
                          <form className="form" action="" id="register" method="post" onSubmit={this.handleSubmit}>
                              <div className="inputBox">
                                <input type="text" value={name} onInput={this.handleChangeName} placeholder="Pseudo" required/>
                              </div>
                              <div className="inputBox">
                                <input type="email" value={email} onInput={this.handleChangeEmail} placeholder="Email" required/>
                              </div>
                              <div className="inputBox">
                                <input type="password" value={password} onChange={this.handleChangePassword} placeholder="Mot de passe" required/>
                              </div>
                              <div className="links">
                                <p>Déjà un compte ?</p>
                                <Link to={`${process.env.REACT_APP_FRONT_URL}/login`}>Connexion</Link>
                              </div>
                              <div className="inputBox">
                                <input type="submit" value="S'inscrire"/>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </section>
        </div>
      </>
    );
  }
}

export default Register;
