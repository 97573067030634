import React, { Component } from 'react';
import API from '../utils/Axios';
import Snipper from './Snipper';
import ImageTransition from '../utils/ImageTransition';

import '../assets/css/vote.css';

class Vote extends Component {
  state = {
      list: [],
      spinner: <Snipper/>,
  }

  componentDidMount() {
      API()
        .get(`/vote/get`)
        .then(res => {
          this.setState({ list: res.data.vote });
          this.setState({ spinner: '' });
      })
  }

  render() {
    return (
      <>
        <div className="vote">

          {/* Info */}
          <div className="info">
						<h2>VOTE</h2>
            <h3>Système de vote FIREBLOCK</h3>
						<p>Le système de vote permet de soutenir soutenir le serveur FIREBLOCK en votant régulièrement sur des plateformes de référencement.</p>
			      <p>En échange, ils reçoivent des points de vote, qui peuvent être utilisés pour obtenir des récompenses exclusives en jeu.</p>
					</div>

          {/* Vote */}
          {this.state.spinner}
          <div className='content'>
            {this.state.list && this.state.list.map((data) => (
              <a href={ data.URL } target="_blank" rel="noreferrer">
                <div className='card'>
                  <p className="title">{data.NAME}</p>
                  <ImageTransition
                    src={`${process.env.REACT_APP_API_BASE}${data.IMAGE}`}
                    alt="Banner"
                  />
                  <div className='point'>
                    <small><i className="fa-solid fa-plus"></i> {data.POINT} point(s) de vote</small>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </>
    )
  }
}

export default Vote;
