import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../utils/Pagination';
import SkeletonTicket from '../../utils/Skeleton/SkeletonTicket';
import fetchData from '../../api/Ticket/Get';

import '../../assets/css/ticket/ticket.css';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class Ticket extends Component {
  state = {
    tickets: null,
    page: 1,
    limit: 10,
    size: 0,
    search: null,
    category: 'created_at',
    statut: "all",
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      try {
        const data = await fetchData({
          offset: (this.state.page * this.state.limit) - this.state.limit,
          search: this.state.search,
          category: this.state.category,
          limit: this.state.limit,
          order: 'desc',
          statut: this.state.statut,
        });
        this.setState({
          tickets: data.datas.data,
        });
      } catch (error) {
        console.error("Erreur interne", error);
      }
    }
  }

  handlePage = (page) => {
    if (localStorage.getItem("token")) {
      if (this.state.page !== page) {
        this.setState({ 
          page: Number(page),
          tickets: null,
        }, () => {
          this.componentDidMount();
        });
      }
    }
  }

  handleSearch = (event) => {
    if (localStorage.getItem("token")) {
      this.setState({ 
        search: event.target.value,
        list: '',
        page: 1,
      }, () => {
        this.componentDidMount();
      });
    }
  }

  handleStatut = (statut) => {
    if (localStorage.getItem("token")) {
      this.setState({ 
        statut: statut,
        tickets: null,
      }, () => {
        this.componentDidMount();
      });
    }
  }
  
  render() {
    const { tickets, statut } = this.state;
    const totalPages = 1;

    let skeletonCount = 10;
    if (tickets) {
      skeletonCount = 10 - tickets.length;
      skeletonCount = skeletonCount > 0 ? skeletonCount : 0;
    }

    return (
      <>
        <div className="ticket">

          {/* Infos */}
          <div class="info">
            <h2>Tickets</h2>
            <h3>Communication avec les membres de l'équipe</h3>
            <p>Avez-vous une question ou un problème ? Le système de tickets d'assistance nous permet de répondre à vos demandes. Soumettez un ticket et nous vous répondrons.</p>
          </div>

          <div className={`ticket-global ${!localStorage.getItem("token") ? 'none' : ''}`}>

            {/* Nav */}
            <div className='nav'>
              <ul>
                <div className='left'>
                  <li className={`${statut === 'all' ? 'selected' : ''}`} onClick={() => this.handleStatut('all')}>Tous</li>
                  <li className={`${statut === 'waiting' ? 'selected' : ''}`} onClick={() => this.handleStatut('waiting')}>En attente</li>
                  <li className={`${statut === 'open' ? 'selected' : ''}`} onClick={() => this.handleStatut('open')}>Ouvert</li>
                  <li className={`${statut === 'close' ? 'selected' : ''}`} onClick={() => this.handleStatut('close')}>Fermé</li>
                </div>
                <div className='right'>
                  <li className='search'>
                    <input
                      type="text" 
                      value={this.state.search || ''} 
                      onChange={this.handleSearch} 
                      placeholder="Rechercher un ticket" 
                      disabled={!localStorage.getItem("token")}
                    />
                  </li>
                  <li className='button'>
                    {localStorage.getItem("token") ? (
                      <Link to="/support/add">
                        Nouveau
                      </Link>
                    ) : (
                      <Link to="/support">
                        Nouveau
                      </Link>
                    )}
                    {/* <button>Nouveau</button> */}
                  </li>
                </div>
              </ul>
            </div>

            {/* Ticket */}
            <div className={`ticket-content ${!localStorage.getItem("token") ? 'none' : ''}`}>

              {/* Table */}
              <table>
                <thead>
                  <tr>
                    <th className="id"><i class="fa-solid fa-sort"></i> ID</th>
                    <th className="statut"><i class="fa-solid fa-sort"></i> Statut</th>
                    <th className="objet"><i class="fa-solid fa-sort"></i> Objet</th>
                    <th className="response"><i class="fa-solid fa-sort"></i> Réponse</th>
                    <th className="date"><i class="fa-solid fa-sort"></i> Date</th>
                    <th className="options"></th>
                  </tr>
                </thead>
                <tbody>
                  {tickets && tickets.length > 0 && (
                    tickets.map((ticket) => (
                      <tr className='ticket-item'>
                        {/* ID */}
                        <td className="id">#{ticket.id}</td>

                        {/* Statut */}
                        <td className="statut">
                          <span className={
                            ticket.statut === 1 ? "open" :
                            ticket.statut === 2 ? "waiting" :
                            "close"
                          }>
                            {ticket.statut === 1 ? "Ouvert" :
                             ticket.statut === 2 ? "En attente" :
                             "Fermé"}
                          </span>
                        </td>

                        {/* Objet */}
                        <td className="objet">
                          {localStorage.getItem("token") ? (
                            <Link to={`${process.env.REACT_APP_FRONT_URL}/support/${ticket.id}`}>
                              {ticket.title}
                            </Link>
                          ) : (
                            <Link to="/support">
                              {ticket.title}
                            </Link>
                          )}
                        </td>

                        {/* Response */}
                        <td className="response">
                          Réponses: {ticket.messages.length}
                        </td>

                        {/* Date */}
                        <td className="date">
                          Créé <ReactTimeAgo date={new Date(ticket.user.created_at)} locale="fr-FR" />
                        </td>

                        {/* Options */}
                        <td className="options">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {/* Skeleton Loader */}
              {Array.from({ length: skeletonCount }).map(() => (
                <SkeletonTicket id="ticket"/>
              ))}

              {/* Pagination */}
              <Pagination
                totalPages={totalPages}
                currentPage={this.state.page}
                onPageChange={this.handlePage}
              />
            </div>
          </div>

          {/* Connect */}
          {!localStorage.getItem("token") && (
            <Link to="/login">
              <div className="connect">
                Vous devez vous connecter pour créer un ticket.
              </div>
            </Link>
          )}
        </div>
      </>
    )
  }
}

export default Ticket;