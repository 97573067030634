import React, { Component } from 'react';

import '../assets/css/utils/spinner.css';

import { ReactComponent as SpinnerSVG } from '../assets/svg/spinner.svg';

class Spinner extends Component {

  render() {
    return (
      <>
        <div className="spinner">
          <SpinnerSVG/>
        </div>
      </>
    )
  }
}

export default Spinner;
