// src/utils/Pagination.js
import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  let elements = [];
  const maxVisible = 5;
  const halfVisible = Math.floor(maxVisible / 2);
  let startPage = Math.max(1, currentPage - halfVisible);
  let endPage = Math.min(totalPages, currentPage + halfVisible);

  if (totalPages <= maxVisible) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (startPage === 1) {
      endPage = Math.min(totalPages, maxVisible);
    }
    if (endPage === totalPages) {
      startPage = Math.max(1, totalPages - maxVisible + 1);
    }
  }

  const prevPage = Math.max(1, currentPage - 1);
  const nextPage = Math.min(totalPages, currentPage + 1);
  const lastPage = totalPages;

  // Previous
  elements.push(
    <p key="totalPrev" onClick={() => onPageChange(1)}>
      <i className="fa-solid fa-angles-left"></i>
    </p>
  );
  elements.push(
    <p key="prev" onClick={() => onPageChange(prevPage)}>
      <i className="fa-solid fa-chevron-left"></i>
    </p>
  );

  // Current pages
  for (let i = startPage; i <= endPage; i++) {
    elements.push(
      <p key={i} onClick={() => onPageChange(i)} className={i === currentPage ? 'enabled' : ''}>
        {i}
      </p>
    );
  }

  // Next
  elements.push(
    <p key="next" onClick={() => onPageChange(nextPage)}>
      <i className="fa-solid fa-chevron-right"></i>
    </p>
  );
  elements.push(
    <p key="totalNext" onClick={() => onPageChange(lastPage)}>
      <i className="fa-solid fa-angles-right"></i>
    </p>
  );

  return <div className="page">{elements}</div>;
};

export default Pagination;
