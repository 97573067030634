import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footerData from '../assets/data/footer.json';

import '../assets/css/layouts/footer.css';

class Footer extends Component {
  render() {
    return (
      <>
        <div className='footer'>

          {/* Networks */}
          <section className='networks'>
            <div className='network'>
              <ul>
                {footerData.networks.map((item) => (
                  <li key={item.name}>
                    <a className={item.name} href={item.url} target="_blank" rel="noreferrer" aria-label={item.name}>
                      <i className={item.icon}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Footer */}
          <section className='bottom'>
            <div className='sub'>
              {footerData.footer.map((item, index) => (
                <Link key={index} to={item.url}>
                  <i className={item.icon}></i> {item.name}
                </Link>
              ))}
            </div>
            {/* <div className='info'>FIREBLOCK n'est pas affilié à Mojang. (Développé par <span>Roux_Cool</span>)</div> */}
            <div className='info'>FIREBLOCK n'est pas affilié à Mojang.</div>
            <div className='src'>FIREBLOCK ©2025</div>
          </section>
        </div>
      </>
    )
  }
}

export default Footer;