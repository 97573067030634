import React, { Component } from 'react';
import '../assets/css/utils/not-found.css';

class NotResult extends Component {

  render() {
    return (
      <>
        <div className="no-found main">
            <i class="fa-regular fa-circle-xmark"></i>
            {/* <i class="fa-solid fa-circle-xmark"></i> */}
            <h4>Désolé, aucun résultat n'a été trouvé.</h4>
        </div>
      </>
    )
  }
}

export default NotResult;
