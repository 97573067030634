import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const GetDatas = async ({ name, secret }) => {
  try {
    const { data } = await API(false).post(`/qrcode`, {
        name: name,
        secret: secret,
    });

    return data;
  } catch (error) {
    handleApiError(error, false);
  }
};

export default GetDatas;