import React from "react";
import PropTypes from "prop-types";
import "../assets/css/utils/tooltip.css";

const Tooltip = ({ children, text, position }) => {
    return (
        <div className="tooltip-container">
            {children}
            <div className={`tooltip-content tooltip-${position}`}>
                {text}
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    position: PropTypes.oneOf(["top", "bottom", "left", "right"]).isRequired,
};

export default Tooltip;
