import Swal from "sweetalert2";

const handleApiError = (error, displayError = true) => {
  const { response } = error;
  let errorMessages = "Une erreur interne s'est produite.";

  if (response) {
    const { status, data } = response;

    // 422 (Validation Errors)
    if (status === 422 && data?.errors) {
      errorMessages = '';
      Object.values(data.errors).forEach((errorsArray) => {
        errorsArray.forEach((err) => {
          errorMessages += `${err}<br>`;
        });
      });
    }

    // 403 (Forbidden)
    else if (status === 403) {
      errorMessages = data.message || "Vous n'avez pas les droits nécessaires.";
    }

    // 500 (Internal Server Error)
    else if (status === 500) {
      errorMessages = errorMessages || "Vous n'avez pas les droits nécessaires.";
    }

    // Others
    else if (data?.message) {
      errorMessages = data.message;
    }
  }
  if (displayError) {
    Swal.fire({
      icon: "error",
      html: errorMessages,
    });
  }
};

export default handleApiError;