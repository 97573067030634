import React, { Component } from 'react';
import API from '../utils/Axios';
import Snipper from './Snipper';

import '../assets/css/profil.css';

// import TimeAgo from 'javascript-time-ago';
// import ReactTimeAgo from "react-time-ago";
// import fr from 'javascript-time-ago/locale/fr.json';
// TimeAgo.addLocale(fr);

class Profil extends Component {
  state = {
      player: localStorage.getItem("player") ? localStorage.getItem("player") : undefined,
      spinner: <Snipper/>,
      profil: 'general',
      data: [],
  }

  componentDidMount() {
    if (this.state.player) {
      API()
        .get(`/profil/get/`+this.state.player)
        .then(res => {
          this.setState({ spinner: '' });
          this.setState({ data: res.data.profil });
      })
    }
  }

  render() {
    let button;
    let content;
    let title;
    // let timeStamp = Date.parse(this.state.data.ACCOUNT_CREATE);
    if (this.state.profil === 'general') {
      title = "Profil";
      button =  
                <>
                  <button className="active" onClick={()=>this.setState({ profil: "general" })}>Général</button>
                  <button onClick={()=>this.setState({ profil: "stats" })}>Statistiques</button>
                </>;
      content = <>
                  <p>Pseudo : {this.state.data.NAME}</p>
                  <p>Firecoins : {this.state.data.FIRECOINS}</p>
                  <p>Crédits : {this.state.data.CREDITS}</p>
                  <p>Version : {this.state.data.VERSION}</p>
                  {/* Posté <ReactTimeAgo date={new Date(data.created_at)} locale="fr-FR"/> */}
                  {/* <p>Création : <ReactTimeAgo date={new Date(this.state.data.ACCOUNT_CREATE)} locale="fr-FR"/></p> */}
                  {/* <p>Création : <ReactTimeAgo date={new Date(timeStamp)} locale="fr-FR"/></p> */}
                  <p>Création : {this.state.data.ACCOUNT_CREATE}</p>
                </>
    } else if (this.state.profil === 'stats') {
      title = "Statistiques";
      button =  <>
                  <button onClick={()=>this.setState({ profil: "general" })}>Général</button>
                  <button className="active" onClick={()=>this.setState({ profil: "stats" })}>Statistiques</button>
                </>;
      content = "";
    }
    
    return (
      <>
        {this.state.spinner}
        {this.state.player && this.state.player !== undefined ? (
          <div className="profil">
            {/* <h1>Profil</h1> */}
            <div className='user'>
              <div>
                <img src={`${process.env.REACT_APP_API_BASE}img/avatar/${this.state.player}.png`} alt={this.state.player}/>
                <p className='pseudo'>fireblock.fun</p>
                {button}
              </div>
            </div>
            <div className='general'>
              <div className="imge">
                <div className="icon"></div>
                <p className="title">{title}</p>
              </div>
              <div className="description">
                <div className="content">
                  {content}
                </div>
              </div>
            </div>
          </div>
        ) : (
          null
        )}
      </>
    )
  }
}

export default Profil;
