import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../utils/Avatar';
import Pagination from '../../utils/Pagination';
import SkeletonTopic from '../../utils/Skeleton/SkeletonTopic';
import SkeletonBreadcrumb from '../../utils/Skeleton/SkeletonBreadcrumb';
import fetchData from '../../api/Forum/GetTopic';

import '../../assets/css/forum/forum-topic.css';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class ForumTopic extends Component {
  state = {
    forums: [],
    topics: null,

    page: 1,
    limit: 10,
    size: 0,
    search: null,
    category: 'ID',
  }

  async componentDidMount() {
    const { forum, topic } = this.props;

    try {
      const data = await fetchData(forum, topic);
      this.setState({
        forums: data.forum, 
        topics: data.topic,
      });
    } catch (error) {
      console.error("Erreur interne", error);
    }
  }

  handlePage = (page) => {
    if (this.state.page !== page) {
      this.setState({ 
        page: Number(page),
        list: '',
      }, () => {
        this.componentDidMount();
      });
    }
  }
  
  render() {
    const { forum, topic } = this.props;
    const { topics } = this.state;
    const totalPages = 1;
  
    // Calculer le nombre de skeletons restants
    let skeletonCount = 10;
    if (topics) {
      skeletonCount = 10 - topics.reduce((acc, curr) => acc + curr.posts.length, 0);
      skeletonCount = skeletonCount > 0 ? skeletonCount : 0;
    }

    return (
      <>
        {topics ? (
          <div className="topic">

            {/* Breadcrumb */}
            <div className='breadcrumb'>
              <Link to="/forum">Forum</Link> <i className="fa-solid fa-chevron-right"></i>
              <Link className="current" to={`/forum/${forum}/${topic}`}>
                {topics.length > 0 ? topics[0].title : ''}
              </Link>
            </div>

            {/* Topic */}
            <div className='topic-global'>

              {/* Pagination */}
              <Pagination
                totalPages={totalPages}
                currentPage={this.state.page}
                onPageChange={this.handlePage}
              />

              {/* Topic */}

              <h1>{topics.length > 0 ? topics[0].title : ''}</h1>
              {topics.map((topic, topicIndex) => (
                <div key={topicIndex} className="topics-item">
                  <div className="topic-posts">
                    {topic.posts.map((post) => (
                      <div key={post.id} className="post-item">
                        <div className='icon'>
                          <i className="fa-solid fa-comments"></i>
                        </div>
                        <div className='first'>
                          <Link to={`${process.env.REACT_APP_FRONT_URL}/forum/${this.state.forums.id}/${topic.id}/${post.id}`}>
                            {post.title}
                          </Link>
                        </div>
                        <div className='second'>
                          Réponses<span> {post.messages.length}</span>
                        </div>
                        <div className='second'>
                          Affichages<span> {post.views.length}</span>
                        </div>
                        <div className='third'>
                          <small>
                            <Avatar name={post.user.name} width="35px" height="35px" borderRadius="10px"/>
                          </small>
                          <small></small>
                          <small>
                            <p>{post.user.name}</p>
                            <p>Posté <ReactTimeAgo date={new Date(post.user.created_at)} locale="fr-FR"/></p>
                          </small>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              {/* Skeleton Loader */}
              {Array.from({ length: skeletonCount }).map(() => (
                <SkeletonTopic id="topic"/>
              ))}

              {/* Pagination */}
              <Pagination
                totalPages={totalPages}
                currentPage={this.state.page}
                onPageChange={this.handlePage}
              />
            </div>
          </div>
        ) : (
          <div className="topic">

            {/* Breadcrumb */}
            <div className='breadcrumb'>
              <SkeletonBreadcrumb id="breadcrumb"/>
            </div>

            {/* Topic */}
            <div className='topic-global'>

              {/* Skeleton Loader */}
              {Array.from({ length: skeletonCount }).map(() => (
                <SkeletonTopic id="topic"/>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default ForumTopic;