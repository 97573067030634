import React, { Component } from 'react';
import IntervalDiff from './IntervalDiff';
import Avatar from './Avatar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCircle,
} from '@fortawesome/free-solid-svg-icons';

import TimeAgo from 'javascript-time-ago';
// TimeAgo.addLocale(fr);

class Online extends Component {

    isOnline(date) {
        if (IntervalDiff(date, 15)) {
            return 1;
        }
        if (IntervalDiff(date, 30)) {
            return 2;
        }
        return 0;
    }

    render() {
        const { name, date } = this.props;
        const timeAgo = new TimeAgo('fr');
        const last = timeAgo.format(new Date(date));
        if (this.isOnline(date) === 1) {
            return (
                <div className='status-player online'>
                    <button type="button" class="" data-toggle="tooltip" data-placement="top" title={`${name}\nEn ligne ${last}`} >
                        <FontAwesomeIcon icon={faCircle}/>
                        <Avatar name={name} />
                    </button>
                </div>
            );
        } else if (this.isOnline(date) === 2) {
            return (
                <div className='status-player afk'>
                    <button type="button" class="btn" data-toggle="tooltip" data-placement="top" title={`${name}\nEn ligne ${last}`} >
                        <FontAwesomeIcon icon={faCircle}/>
                        <Avatar name={name} />
                    </button>
                </div>
            );
        } else {
            return (
                <div className='status-player offline'>
                    <button type="button" class="btn" data-toggle="tooltip" data-placement="top" title={name} >
                        <FontAwesomeIcon icon={faCircle}/>
                        <Avatar name={name} />
                    </button>
                </div>
            );
        }
    }
}

export default Online;