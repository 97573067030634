import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './views/Home';
import NotFound from './views/Utils/NotFound';
import VPN from './views/Utils/VPN';

import Host from './views/Host/Host';
import HostShow from './views/Host/HostShow';

import Ticket from './views/Ticket/Ticket';
import TicketAdd from './views/Ticket/TicketAdd';
import TicketShow from './views/Ticket/TicketShow';

import Forum from './views/Forum/Forum';
import ForumTopic from './views/Forum/ForumTopic';
import ForumPost from './views/Forum/ForumPost';

import Auth from './views/Auth/Auth';
import Login from './views/Auth/Login';
import Logout from './components/Auth/Logout';
import Register from './views/Auth/Register';

import ArticleShowOne from './views/Article/ArticleShowOne';
import Vote from './views/Vote';
import Profil from './views/Profil';
import Shop from './views/Shop/Shop';
import QRCode from './views/QRCode';

import Test from './views/Test';
// import Chat from "./components/WebSocket/Chat";

import './assets/css/master.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="*" element={<NotFound/>} />
        <Route path="/vpn" element={<VPN/>} />

        <Route path="/host" element={<Host/>} />
        <Route path="/host/:host" element={<HostShow/>} />

        <Route path="/support" element={<Ticket/>} />
        <Route path="/support/add" element={<TicketAdd/>} />
        <Route path="/support/:ticket" element={<TicketShow/>} />

        <Route path="/auth/:token" element={<Auth/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/register" element={<Register/>} />

        <Route path="/article/:id" element={<ArticleShowOne/>} />

        <Route path="/forum" element={<Forum/>} />
        <Route path="/forum/:forum/:topic" element={<ForumTopic />} />
        <Route path="/forum/:forum/:topic/:post" element={<ForumPost />} />

        <Route path="/shop" element={<Shop/>} />

        <Route path="/stats" element={<Register/>} />
        <Route path="/vote" element={<Vote/>} />
        <Route path="/profil" element={<Profil/>} />
        <Route path="/test" element={<Test />} />

        <Route path="/qrcode/:name/:secret" element={<QRCode />} />
        {/* <Route path="/chat" element={<Chat/>} /> */}
      </Routes>
    </Router>
  );
};

export default App;