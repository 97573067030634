import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const GetDatas = async (forum, topic, post) => {
  try {
    const { data } = await API().get(`/forum/post/all/${forum}/${topic}/${post}`);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default GetDatas;
