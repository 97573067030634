import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import fetchData from '../api/Server/GetPlayersList';
import Avatar from '../utils/Avatar';
import navData from '../assets/data/nav.json';

import '../assets/css/layouts/header.css';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: this.props.nav || null,
      scrolled: false,
      logo: true,
      isVisible: localStorage.getItem("nav-visible") === "true",
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    localStorage.setItem("nav-visible", this.state.isVisible);
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  
    try {
      const playersData = await fetchData();
      localStorage.setItem('online-player', playersData?.players || 0);
    } catch (error) {
      console.error("Error server datas :", error);
    }

    const avatar = document.querySelector('.nav-account');
    const menu = document.querySelector('.menu');
  
    if (avatar && menu) {
      avatar.addEventListener('mouseover', () => {
        menu.style.display = 'block';
      });
  
      menu.addEventListener('mouseleave', () => {
        menu.style.display = 'none';
      });
  
      document.addEventListener('click', (event) => {
        if (!menu.contains(event.target) && !avatar.contains(event.target)) {
          menu.style.display = 'none';
        }
      });
    }
  }

  handleScroll = () => {
    const offset = window.scrollY;
    this.setState({
      scrolled: offset > 50
    });
  };

  toggleSidebar = () => {
    this.setState(prevState => {
      const newVisibility = !prevState.isVisible;
      localStorage.setItem("nav-visible", newVisibility);
      return { isVisible: newVisibility };
    });

    const header = document.querySelector('.header');
    if (header) {
      header.classList.toggle('active', !this.state.isVisible);
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    const logoSrc = navData.logo;
    const { body, isVisible } = this.state;
    const onlinePlayers = localStorage.getItem('online-player') || 0;

    return (
      <div className={`header ${isVisible ? 'active' : ''}`} id="header">
        <nav className="navbar navbar-expand-lg">
          {/* Button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleSidebar}
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className='burger-close' onClick={() => this.toggleSidebar()}>
            <i className="fa-solid fa-xmark"></i>
          </div>

          {/* Nav */}
          <ul className="nav-center">
            <li className="nav-item">
              <button className="nav-link nav-img">
                <img src={`${process.env.REACT_APP_API_BASE}${logoSrc}`} alt="Logo" className="nav-logo" draggable="false" />
                <i className="fa-solid fa-circle"></i> {onlinePlayers} JOUEURS CONNECTÉS
              </button>
            </li>
            
            {navData.menuItems.map((item) => (
              <li key={item.id} className={`nav-item ${body === item.id ? 'active' : ''}`}>
                <Link className="nav-link nav-animate" to={item.path}>
                  <i className={item.icon}></i> {item.label}
                </Link>
              </li>
            ))}
          </ul>

          {/* Nav right */}
          <ul className="nav-right">
            <li className="nav-item nav-shop" onClick={this.toggleSidebar}>
              <Link className="nav-link" to="/shop">
                <i className="fa-solid fa-shop"></i> Boutique
              </Link>
            </li>

            {!localStorage.getItem('token') ? (
              <li className="nav-item nav-login" onClick={this.toggleSidebar}>
                <Link className="nav-link" to="/login">
                  <i className="fa-solid fa-right-to-bracket"></i> Connexion
                </Link>
              </li>
            ) : (
              <li className="nav-link nav-account">
                <Avatar name={localStorage.getItem('player')}/> <span>Mon compte</span>

                <div className='menu'>
                  <Link className="menu-item" to="/profil">
                    <i className="fa-solid fa-user"></i> Profil
                  </Link>
                  <Link className="menu-item" to="/settings">
                    <i className="fa-solid fa-gear"></i> Paramètres
                  </Link>
                  <Link className="menu-item" to="/logout">
                    <i className="fa-solid fa-right-to-bracket"></i> Déconnexion
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Nav;
