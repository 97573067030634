import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Online from '../../utils/Online';
import Snipper from '../Snipper';
import fetchData from '../../api/Forum/GetForum';

import '../../assets/css/forum/forum.css';

class Forum extends Component {
  state = {
    forums: [],
    spinner: <Snipper/>,
    onlinePlayer: null,
    onlineStaff: null,
  }

  async componentDidMount() {
    try {
      const data = await fetchData();
      this.setState({ 
        forums: data.forum,
        onlinePlayer: data.onlinePlayer,
        onlineStaff: data.onlineStaff,
        spinner: ''
      });
    } catch (error) {
      console.error("Erreur interne", error);
    }
  }
  
  render() {
    return (
      <>
        <div className='forum'>

          {/* Forum info */}

          <div className="forum-info">
						<h2>Forums</h2>
            <h3>Partagez vos meilleurs moments à travers notre communauté</h3>
						<p>Bienvenue sur notre forum, un espace d'échange dédié pour partager, discuter et collaborer autour de vos projets web et technologies.</p>
			      <p>Rejoignez notre communauté <a href="https://discord.com/invite/sGeB8Yc" target='blank'>Discord</a> <i className="fa-brands fa-discord"></i> Échangez avec d'autres joueurs, restez connecté sur nos mises à jour.</p>
					</div>

          {/* Breadcrumb */}

          <div className='breadcrumb'>
            <Link to="/">Accueil</Link> <i className="fa-solid fa-chevron-right"></i>
            <Link className="current" to="/forum">Forum</Link>
          </div>

          {/* Forum */}

          {/* <h1>Forums</h1> */}
          <div className='forum-display forum-content'>
            {this.state.spinner}
            {this.state.forums && this.state.forums.map((forum, forumIndex) => (
              <div key={forumIndex} className="forum-item">
                <h4>{forum.icon ? <div dangerouslySetInnerHTML={{ __html: forum.icon }} /> : null}{forum.name}</h4>
                <span className='underline'></span>
                <div className="forum-topics">
                  {forum.topics && forum.topics.map((topic, topicIndex) => (
                    <div key={topicIndex} className="topic-item">
                      <span className='rod'></span>
                      <span className='icon'>{topic.icon ? <div dangerouslySetInnerHTML={{ __html: topic.icon }} /> : null}</span>
                      <Link to={`${process.env.REACT_APP_FRONT_URL}/forum/${forum.id}/${topic.id}`}>
                        {topic.title}
                      </Link>
                      <div className='post-message'>
                        <div>
                          <i className="fa-solid fa-pen"></i>
                          <p>{topic.posts_count ? topic.posts_count : 0}</p>
                        </div>
                        <div>
                          <i className="fa-regular fa-message"></i>
                          <p>{topic.messages_count ? topic.messages_count : 0}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className='forum-display forum-right'>
            <div className='forum-item forum-staff'>
              <h5>Staff en ligne</h5>
              {this.state.spinner}
              {this.state.onlineStaff && this.state.onlineStaff.map((player, index) => (
                <Online name={player.name} date={player.online}/>
              ))}
            </div>
            <div className='forum-item forum-online'>
              <h5>Membres en ligne</h5>
              {this.state.spinner}
              {this.state.onlinePlayer && this.state.onlinePlayer.map((player, index) => (
                <Online name={player.name} date={player.online}/>
              ))}
            </div>
            <div className='forum-item forum-last'>
              <h5>Derniers messages</h5>
              {this.state.spinner}
            </div>
          </div>
        </div>
      </>
    )
  }
}
  
export default Forum;
