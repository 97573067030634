import * as React from "react";
import { useParams } from 'react-router-dom';

import Ticket from "../../components/Ticket/TicketShow";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Slider from '../../utils/Slider';

function Dashboard() {
  const { ticket } = useParams();

  return (
    <>
      <Header nav="ticket"/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <Ticket id={ticket}/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
