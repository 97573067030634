import React, { Component } from 'react';
import fetchData from '../api/Utils/QRCode';

import "../assets/css/utils/qrcode.css";

class QRCode extends Component {
  state = {
    name: this.props.name,
    secret: this.props.secret,
    image: null,
  }

  componentDidMount() {
    this.fetchQRCode();
  }

  async fetchQRCode() {
    const { name, secret } = this.state;

    try {
        const response = await fetchData({ name, secret });
        if (response && response.image) {
            this.setState({ image: response.image });
        }
    } catch (error) {
        console.error("Erreur interne", error);
    }
  }
  
  render() {
    const { image } = this.state;

    return (
      <div className='qrcode'>
        <div className='content'>
            <h2>QR Code</h2>
            <p>Scannez le qr-code à partir de votre application pour obtenir le code.</p>
            <p>Ensuite, terminez l'opération en jeu.</p>
            
            {image ? (
                <img src={image} alt="QR Code" />
            ) : (
                <div className="skeleton"></div>
            )}
        </div>
      </div>
    );
  }
}

export default QRCode;
