import React, { Component } from 'react';
import '../assets/css/utils/not-found.css';

class NotFound extends Component {

  render() {
    return (
      <>
        <div className="no-found main">
            <i class="fa-solid fa-shield-halved"></i>
            <h4>Désactivez votre VPN/PROXY pour accéder à l'application.</h4>
        </div>
      </>
    )
  }
}

export default NotFound;
