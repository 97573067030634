import React, { Component } from 'react';
import API from '../utils/Axios';

import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Slider from '../utils/Slider';

class GeneratePDF extends Component {
  state = {

  };

  componentDidMount() {
    API()
      .get(`/host`, {})
      .then(data => {
        const datas = data;
        console.log("datas", datas.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération du PDF:', error);
      });
  };

  render() {

    return (
      <>
        <Header/>
        <Slider maxHeight="400px"/>
        <div className="main">
          {/* <Support/> */}
        </div>
        <Footer/>
      </>
    );
  }
}

export default GeneratePDF;
