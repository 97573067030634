import React, { Component } from 'react';
import fetchData from '../../api/Host/Show';
import Avatar from '../../utils/Avatar';
import ImageTransition from '../../utils/ImageTransition';
import Snipper from '../Snipper';
import { ReactComponent as BadgeSVG } from '../../assets/svg/badge.svg';

import Overlay from "../../utils/Overlay";
import overlayData from "../../assets/data/overlay.json";
import '../../assets/css/host/host-show.css';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class HostShow extends Component {
  state = {
    data: null,
    host: this.props.host,
    spinner: <Snipper />,
    overlayVisible: false,
    overlayTitle: "",
    overlayContent: "",
    overlayActions: {},
    overlayDatas: {},
    codeDefault: 'Cliquez pour copier le code',
    codeText: '',
    codeCopied: 'Copié !'
  };
  

  async componentDidMount() {
    try {
      const data = await fetchData({ host: this.state.host });
      this.setState({
        data: data.host,
        size: data.size,
        spinner: '',
      });
    } catch (error) {
      console.error("Erreur interne", error);
    }
  }

  handleImageError = (event) => {
    event.target.src = process.env.REACT_APP_API_BASE+'img/banner/banner-official.jpg';
  }

  isValidDate(dateString) {
    const [day, month, year, time] = dateString.split(/[/\s:]/);
    const isoDateString = `20${year}-${month}-${day}T${time}:00`;
    const date = new Date(isoDateString);
    return date instanceof Date && !isNaN(date.getTime());
  }

  formatDate = (dateString) => {
    const [day, month, year, time] = dateString.split(/[/ ]/);
    return new Date(`20${year}-${month}-${day}T${time}:00`);
  };

  formatCode = (code) => {
    return code.replace(/^(\d{3})(\d{3})(\d{2})$/, "$1-$2-$3");
  };

  copyToClipboard = (text) => {
    const { codeText, codeCopied } = this.state;

    if (codeText !== codeCopied) {
      navigator.clipboard.writeText(text);
      this.setState({ codeText: codeCopied });
      setTimeout(() => {
          this.setState({ codeText: '' });
      }, 1000);
    }
  };

  toggleOverlay = (id, datas = null) => {
    if (!id || !overlayData[id]) return;

    this.setState({
      overlayVisible: !this.state.overlayVisible,
      overlayTitle: overlayData[id].title,
      overlayContent: overlayData[id].description,
      overlayActions: overlayData[id].action,
      overlayDatas: datas,
    });
  };
  
  render() {
    const { data, overlayVisible, overlayTitle, overlayContent, overlayActions, overlayDatas, codeText, codeDefault } = this.state;

    return (
      <>
        {/* Overlay */}

        <Overlay
          visible={overlayVisible}
          title={overlayTitle}
          content={overlayContent}
          actions={overlayActions}
          onClose={() => this.setState({ overlayVisible: false })}
          datas={overlayDatas}
        />

        {/* Nav */}

        <div className="host-show">

          {/* Dispaly Host */}

          <div className="host-display">
            {this.state.spinner}
            {data && (
              <div className="host-content">

                <h2>{data.CATEGORY} - {data.THEME}</h2>
                
                {/* Head */}
                <div className="head">
                  <ImageTransition
                    src={`${process.env.REACT_APP_API_BASE}img/host/${data.GAME}/${data.GAME}.jpg`}
                    onError={this.handleImageError}
                    alt={data.GAME}
                  />
                </div>
                
                {/* Info */}
                <div className='info'>
                  {/* Left */}
                  <div className='left'>
                    <div className='sub'>
                      <Avatar name={data.AUTHOR}/>
                    </div>
                  </div>

                  {/* Right */}
                  <div className='right'>
                    <div className='sub'>
                      <span className='author'>{data.AUTHOR}</span>
                      <span className='subscribe'>0 Abonnés</span>
                    </div>

                    {!localStorage.getItem('token') ? (
                      <button onClick={() => this.toggleOverlay("connect")}>
                        S'abonner
                      </button>
                    ) : (
                      <button onClick={() => this.toggleOverlay("connect")}>
                        S'abonner
                      </button>
                    )}
                  </div>
                </div>

                {/* Action */}
                <div className='action'>
                  {!localStorage.getItem('token') ? (
                    <>
                      <button onClick={() => this.toggleOverlay("connect")}>
                        <i className="fa-regular fa-star"></i> Favoris
                      </button>
                      <button onClick={() => this.toggleOverlay("connect")}>
                        <i className="fa-regular fa-thumbs-up"></i> {data.LIKESTOTAL}
                      </button>
                    </>
                  ) : (
                    <>
                      <button>
                        <i className="fa-regular fa-star"></i> Favoris
                      </button>
                      <button>
                        <i className="fa-regular fa-thumbs-up"></i> {data.LIKESTOTAL}
                      </button>
                    </>
                  )}

                  <button onClick={() => this.toggleOverlay("share", {"copy":`${process.env.REACT_APP_FRONT_URL}/host/${data.GAME}`})}>
                    <i className="fa-solid fa-share"></i> Partager
                  </button>
                  <button onClick={() => this.toggleOverlay("report")}>
                    <i className="fa-regular fa-flag"></i> Signaler
                  </button>
                </div>

                {/* Bottom */}
                <div className='bottom'>
                  <div className='first'>
                    <div>
                      <span>0 vues</span> ▪ 
                      <span>{data.LIKESTOTAL} likes</span> ▪ 
                      <span className='date'>
                        {this.isValidDate(data.DATE_CREATE) ? (
                          <ReactTimeAgo date={this.formatDate(data.DATE_CREATE)} locale="fr-FR"/>
                        ) : (
                          <span>Date invalide</span>
                        )}
                      </span>
                    </div>

                    <div className='badge'>
                      <BadgeSVG class='tooltip-btn top' data-tooltip="Top 1"/>
                      <BadgeSVG class='tooltip-btn certify' data-tooltip="Certifié"/>
                      <BadgeSVG class='tooltip-btn origin' data-tooltip="Créativité"/>

                      <div class="tooltip" id="tooltip-top">Top 1</div>
                      <div class="tooltip" id="tooltip-certify">Certifié</div>
                      <div class="tooltip" id="tooltip-origin">Créativité</div>
                    </div>
                  </div>

                  <div className='second'>
                    <p className='description'>{data.DESCRIPTION}</p>
                    <div className='code' onClick={() => {
                        this.copyToClipboard(data.GAME);
                      }}>
                      <span>{this.formatCode(data.GAME)}</span>
                      <p>{codeText ? codeText : codeDefault}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}
  
export default HostShow;