import API from '../../utils/Axios';
import Swal from 'sweetalert2';
import handleApiError from '../../utils/handleApiError';

const Auth = async (token, callback) => {
  try {
    const { data } = await API().post(`/auth`, { token });
    
    if (callback) {
      Swal.fire({
        icon: "success",
        text: data.message,
      }).then(() => {
        callback(data);
      });
    }
  } catch (error) {
    handleApiError(error);
  }
};

export default Auth;