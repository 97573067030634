import * as React from "react";

import Forum from "../../components/Forum/Forum";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Slider from '../../utils/Slider';

function Dashboard() {
  return (
    <>
      <Header nav="forum"/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <Forum/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
