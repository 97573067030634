import * as React from "react";
import { useParams } from 'react-router-dom';

import HostShow from "../../components/Host/HostShow";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Slider from '../../utils/Slider';

function Dashboard() {
  let { host } = useParams();

  return (
    <>
      <Header nav="host"/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <HostShow host={host} />
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
