import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const EditDatas = async (id, message) => {
  try {
    const { data } = await API().put(`/forum/message/${id}`, { message: message });
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default EditDatas;
