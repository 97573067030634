import * as React from "react";

import Ticket from "../../components/Ticket/Ticket";
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import Slider from '../../utils/Slider';

function Dashboard() {
  return (
    <>
      <Header nav="support"/>
      <Slider maxHeight="400px"/>
      <div className="main">
        <Ticket/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
