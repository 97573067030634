import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import loginDatas from '../../api/Auth/Login';
import Swal from 'sweetalert2';

import '../../assets/css/auth/login.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      recaptchaToken: null,
      redirectToHome: false,
    };
    this.recaptchaRef = React.createRef();
  }

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  handleRecaptchaChange = (token) => {
    this.setState({ recaptchaToken: token });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password, recaptchaToken } = this.state;

    if (!recaptchaToken) {
      Swal.fire({
        icon: "error",
        title: "Échec",
        text: "Veuillez valider le CAPTCHA",
      });
      return;
    }

    loginDatas(email, password, (data) => {
      localStorage.setItem("token", data.token);
      localStorage.setItem("player", data.user.name);
      localStorage.setItem("id", data.user.id);
      this.setState({ redirectToHome: true });
    });

    this.recaptchaRef.current.reset();
  }

  render() {
    const { email, password, redirectToHome } = this.state;

    if (redirectToHome) {
      return <Navigate to="/" />;
    }

    return (
      <>
        <div className='login'>
          <div className="content">
            <h2>Connexion</h2>
            <form className="form" onSubmit={this.handleSubmit}>
              <div className="inputBox">
                <input type="email" value={email} onChange={this.handleChangeEmail} placeholder="Email" required />
              </div>
              <div className="inputBox">
                <input type="password" value={password} onChange={this.handleChangePassword} placeholder="Mot de passe" required />
              </div>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                className='recaptcha'
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={this.handleRecaptchaChange}
              />
              <div className="links">
                <Link to="/forgot-password">Mot de passe oublié ?</Link>
                <Link to="/register">Inscription</Link>
              </div>
              <div className="inputBox">
                <input type="submit" value="Se connecter" />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
