import React, { Component } from 'react';
import Snipper from '../Snipper';
import { Link } from 'react-router-dom';
import API from '../../utils/Axios';
import Swal from 'sweetalert2';
import EditorShow from '../../utils/Editor/EditorShow';
import SkeletonShop from '../../utils/Skeleton/SkeletonShop';
import SkeletonShopBreadcrumb from '../../utils/Skeleton/SkeletonShopBreadcrumb';

import '../../assets/css/shop/shop.css';

class Shop extends Component {
  state = {
    id: 1,
    category: [],
    product: [],
    filter: [
      { key: 'featured', value: 'En vedette' },
      { key: 'best-seller', value: 'Meilleures ventes' },
      { key: 'reviews', value: 'Meilleures notes' },
      { key: 'a-z', value: 'Alphabétique, de A à Z' },
      { key: 'z-a', value: 'Alphabétique, de Z à A' },
      { key: 'price-smallest-largest', value: 'Prix : faible à élevé' },
      { key: 'price-largest-smallest', value: 'Prix : élevé à faible' },
      { key: 'date-oldest-newest', value: 'Date : du plus ancien au plus récent' },
      { key: 'date-newest-oldest', value: 'Date : du plus récent au plus ancien' },
    ],
    filterSelect: 'featured',
    showFilters: false,
    navOpen: false,
    displayContent: 'items',

    quantity: {},
    purchase: JSON.parse(localStorage.getItem("purchase")) || {},
  };

  componentDidMount() {
    localStorage.removeItem("purchase");
    // console.log("purchase", JSON.parse(localStorage.getItem("purchase")));
    this.handleData(this.state.id);
  }

  handleData = (nav, type) => {
    API()
      .get(`/product/category/get/${nav}/${type ? type : this.state.filterSelect}`)
      .then(res => {
        this.setState({
          category: res.data.category,
          product: res.data.product,
        });
      });
  };

  handleNav = (nav) => {
    this.setState({ product: [], id: nav });
    this.handleData(nav);
  };

  handleAddProduct = (item) => {
    const purchase = JSON.parse(localStorage.getItem("purchase")) || {};
    const quantity = this.state.quantity[item.id] || 1;
  
    purchase[item.id] = { ...item, quantity };
  
    localStorage.setItem("purchase", JSON.stringify(purchase));
    this.setState({ purchase: purchase });
    console.log("Produit ajouté :", item.name, "Quantité :", quantity);
    console.log("purchase", purchase, purchase);

    Swal.fire({
      icon: "success",
      text: `${item.name} ajouté au panier`,
    });
  };

  handleDisplay = (value) => {
    this.setState({ displayContent: value, id: null });
  }

  toggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  toggleSidebar = () => {
    this.setState(prevState => {
      const newVisibility = !prevState.isVisible;
      return { isVisible: newVisibility };
    });

    const header = document.querySelector('.shop .nav');
    if (header) {
      header.classList.toggle('active', !this.state.isVisible);
    }
  };

  disableFilters = () => {
    if (this.state.showFilters) this.setState({ showFilters: false });
  };

  disableNav = () => {
    if (this.state.navOpen) this.setState({ navOpen: false });
  };

  handleChangeFilter = (key) => {
    this.setState({ filterSelect: key });
    // this.setState({ product: [] });
    this.handleData(this.state.id, key);
  };

  render() {
    const { purchase, product, showFilters, displayContent } = this.state;
    const count = 4 - product.length;
    console.log("count", count);

    return (
      <div className='shop' onClick={() => this.disableFilters()}>

        {/* Nav */}

        <div className='burger' onClick={() => this.toggleSidebar()}>
          <i class="fa-solid fa-bars"></i>
        </div>

        <nav className='nav'>
          <div className='burger-close' onClick={() => this.toggleSidebar()}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <ul>
            <div className="icons">

              {/* Filtres */}
              <div>
                <i className="fa-solid fa-filter" onClick={this.toggleFilters}></i>
                {showFilters && (
                  <div className='filters'>
                    {this.state.filter.map((f, index) => (
                      <span 
                        key={index}
                        className={f.key === this.state.filterSelect ? 'filter-selected' : ''}
                        onClick={() => this.handleChangeFilter(f.key)}
                      >
                        {f.value}
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <div className='purchase-count' onClick={() => this.handleDisplay('shopping')}>
                {/* Filtres */}
                <div className='purchase'>
                  <i className="fa-solid fa-cart-shopping"></i>
                </div>

                {/* Count */}
                <div className='count'>
                  {Object.keys(purchase).length}
                </div>
              </div>
            </div>

            {this.state.category.map((shop, index) => (
              <li 
                key={index}
                className={shop.id === this.state.id ? "active" : ""}
                onClick={() => {
                  this.handleDisplay('items');
                  this.handleNav(shop.id);
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: shop.icon }} /> {shop.name}
              </li>
            ))}
          </ul>
        </nav>

        {/* Produits */}

        {displayContent === 'items' && (
          <div className='contents'>
            {product && product.length > 0 ? (
              <div className='products'>
                {product.map((data, index) => (
                  <div key={index} className={`items ${data.class}`}>
                    {/* {data.name && (
                      <img src={`${process.env.REACT_APP_API_BASE}${data.image}`} alt={data.name} draggable="false" />
                    )} */}
                    <div className='info'>
                      <h4>{data.name}</h4>
                      <p className='price'>
                        <span className='icon'>{data.payment.icon}</span>
                        <span className='amount'>{parseFloat(data.price) % 1 === 0 ? parseInt(data.price) : data.price}</span>
                      </p>
                      {/* <div className='quantity'>
                        Quantité : 
                        <input
                          type="number"
                          min="1"
                          max="10"
                          value={this.state.quantity[data.id] || 1}
                          onChange={(e) => {
                            const value = Math.max(1, parseInt(e.target.value) || 1);
                            this.setState((prevState) => ({
                              quantity: {
                                ...prevState.quantity,
                                [data.id]: value
                              }
                            }));
                          }}
                        />
                      </div> */}
                      <div className='line'></div>
                      <EditorShow data={data.description ? data.description : 'Aucune description'} />
                      <button className={`action ${purchase[data.id] ? 'added' : ''}`} onClick={() => this.handleAddProduct(data)}>
                        <i className="fa-solid fa-plus"></i> {purchase[data.id] ? 'Déjà ajouté' : 'Ajouter au panier'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className='products'>
                {Array.from({ length: count }).map((_, index) => (
                  <div key={`items skeleton-${index}`} className='items'>
                    <SkeletonShop id="shop" />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {displayContent === 'shopping' && (
          <div className='shopping'>
            <div className='products'>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Nom</th>
                    <th>Prix</th>
                    <th>Quantité</th>
                    <th>Total</th>
                  </tr>
                </thead>
                {Object.keys(purchase).length > 0 ? (
                  <tbody>
                    {Object.keys(purchase).map((key, index) => {
                      const item = purchase[key];
                      const total = item.price * item.quantity;

                      return (
                        <tr key={index}>
                          <td>
                            {/* <img
                              src={`${process.env.REACT_APP_API_BASE}${item.image}`}
                              alt={item.name}
                              style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                            /> */}
                            {item.icon && (
                              <span dangerouslySetInnerHTML={{ __html: item.icon }} />
                            )}
                          </td>
                          <td>{item.name}</td>
                          <td>{parseFloat(item.price) % 1 === 0 ? parseInt(item.price) : item.price} {item.payment?.icon}</td>
                          <td>x{item.quantity}</td>
                          <td>{(total % 1 === 0 ? parseInt(total) : total).toFixed(2)} {item.payment?.icon}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <div className='no-result'>
                    <i class="fa-solid fa-face-sad-tear"></i>
                    <p>Aucun résultats</p>
                  </div>
                )}
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Shop;
