import * as React from "react";

import Profil from "../components/Profil";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

function Dashboard() {
  return (
    <>
      <Header nav="profil"/>
      <div className="main">
        <Profil/>
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
