import React, { Component } from 'react';
import SliderReact from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/slider.css';

import sliders from '../assets/data/slider.json';

class Slider extends Component {
  state = {
    slides: sliders.slides,
    settings: sliders.settings
  };

  render() {
    const onlinePlayers = localStorage.getItem('online-player') || 0;

    return (
      <div className="slider-container">
        <div className='slider' id="slider">
          <SliderReact {...this.state.settings}>
            {this.state.slides.map((slide, index) => (
              <div key={index} className="slide">
                <img
                  src={`${process.env.REACT_APP_API_BASE}${slide.image}`}
                  alt={`Banner ${index + 1}`}
                  className="slide-image slide-zoom"
                  style={{ 
                    maxHeight: this.props.maxHeight ? this.props.maxHeight : '',
                    minHeight: this.props.maxHeight ? this.props.maxHeight : '',
                  }}
                />
              </div>
            ))}
          </SliderReact>
        </div>

        <div className="nav-text">
          <p className="title-button">FIREBLOCK</p>
          <p className="play-button">
            <i className="fa-solid fa-cube"></i> COMMENT JOUER
          </p>
          <p className="connect-button">
            <i className="fa-solid fa-circle"></i> {onlinePlayers} JOUEURS CONNECTÉS
          </p>
        </div>
      </div>
    )
  }
}

export default Slider;
