import React, { Component } from 'react';

import '../../assets/css/article/article.css';
import Snipper from '../Snipper';

import EditorShow from '../../utils/Editor/EditorShow';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class ArticleShow extends Component {
  state = {
    spinner: <Snipper/>,
  }
  render() {
    return (
      <>
        {this.props.articles && this.props.articles.map((data) => (
          <div className="article">
            <div className="user">
              {data.profil ? (
                <div>
                  <a href={process.env.REACT_APP_FRONT_URL+"/profil/" + data.profil.NAME}><img src={`${process.env.REACT_APP_API_BASE}img/avatar/${data.profil.NAME}.png`} alt="Article-Img"/></a>
                  <a className='pseudo' href={process.env.REACT_APP_FRONT_URL+"/profil/" + data.profil.NAME}><p>{data.profil.NAME}</p></a>
                  <p className='grade'>{data.profil.GRADE}</p>
                </div>
              ) : 
                <div>
                  <img src={`${process.env.REACT_APP_API_BASE}img/logo.png`} alt="Article-Img"/>
                  <p className='pseudo'>fireblock.fun</p>
                </div>
              }
            </div>
            <div className="post">
              <div className="imge">
                <div className="icon"></div>
                <p className="title">{data.title}</p>
              </div>

              <div className="description">
                <div className="content">
                  <EditorShow data={data.content}/>
                </div>
              </div>

              <div className="social-media">
                <div className="social-head">
                  <a href="/">
                    0 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
                    </svg>
                  </a>
                  <a href="/">
                    0 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z"/>
                    </svg>
                  </a>
                  <small>
                    Posté <ReactTimeAgo date={new Date(data.created_at)} locale="fr-FR"/>
                  </small>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default ArticleShow;