import * as React from "react";

// import Home from "../components/Home";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Soon from "../utils/Soon";
import Slider from '../utils/Slider';

function Dashboard() {
  return (
    <>
      <Header nav="home"/>
      <Slider maxHeight="600px"/>
      <div className="main">
        <Soon/>
        {/* <Home/> */}
      </div>
      <Footer/>
    </>
  );
}

export default Dashboard;
