import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../utils/Axios';
import Snipper from '../../components/Snipper';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import NotResult from '../../utils/NotResult';
import ArticleShow from '../../components/Article/ArticleShow';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ArticleShowOne extends Component {
  state = {
    id: this.props.params.id,
    articles: [],
    spinner: <Snipper/>,
  }

  componentDidMount() {
    API()
      .get(`/article/get/` + this.props.params.id)
      .then(res => {
          this.setState({ articles: res.data.article });
          this.setState({ spinner: '' });
        }).catch(() => {
        this.setState({ spinner: <NotResult/> });
      });
  }

  render() {
      return (
        <>
          <Header nav="article"/>
          {this.state.spinner}
          <div className="main">
            <ArticleShow articles={this.state.articles}/>
          </div>
          <Footer/>
        </>
    )
  }
}

export default withParams(ArticleShowOne);
