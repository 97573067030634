import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../utils/Avatar';
import addData from '../../api/Ticket/AddMessage';
import delData from '../../api/Ticket/DelMessage';
import editData from '../../api/Ticket/EditMessage';
import fetchData from '../../api/Ticket/Show';
import Editor from '../../utils/Editor/Editor';
import EditorShow from '../../utils/Editor/EditorShow';
import Swal from 'sweetalert2';

import '../../assets/css/ticket/ticket-show.css';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from "react-time-ago";
import fr from 'javascript-time-ago/locale/fr.json';
TimeAgo.addLocale(fr);

class TicketShow extends Component {
  state = {
    list: null,
    page: 1,
    limit: 10,
    size: 0,
    id: this.props.ticket,

    editID: null,
    editData: null,
    messageEdit: null,
    message: null,
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      const { id } = this.props;

      try {
        const data = await fetchData(id);
        this.setState({
          list: data.datas,
        });
      } catch (error) {
        console.error("Erreur interne", error);
      }
    }
  }

  handleSubmit = async () => {
    if (localStorage.getItem("token")) {
      const { message } = this.state;
      const { id } = this.props;

      try {
        const data = await addData({ id, message });
        Swal.fire({
          icon: "success",
          text: data.message,
        }).then(() => {
          this.componentDidMount();
        });
      } catch (error) {
        console.error("Erreur interne", error);
      }
    }
  };

  handleDelete = async (id) => {
    if (localStorage.getItem("token")) {
      try {
        const data = await delData(id);
        await this.componentDidMount();
        Swal.fire({
            icon: "success",
            text: data.message,
        });
      } catch (error) {
        console.error("Erreur interne", error);
      }
    }
  };

  handleUpdate = async (id) => {
    if (localStorage.getItem("token")) {
      const { messageEdit } = this.state;

      try {
          const data = await editData(id, messageEdit);
          this.setState({ 
            messageEdit: '',
            editID: null
          });
          await this.componentDidMount();
          Swal.fire({
              icon: "success",
              text: data.message,
          });
      } catch (error) {
          console.error("Erreur interne", error);
      }
    }
  };

  handleEdit = async (id, message) => {
    if (localStorage.getItem("token")) {
      this.setState({ 
        messageEdit: message,
        editID: id
      });
    }
  };

  handleChangeMessage = (message) => {
    if (localStorage.getItem("token")) {
      this.setState({ message });
    }
  };

  handleChangeMessageEdit = (content) => {
    this.setState({ messageEdit: content });
  }

  render() {
    const { list, editID, messageEdit, message } = this.state;
    let i = 1;

    return (
      <div className="ticket-show">
        {list && (
          <div className="post-global">

            <div className='info'>
              <h2>{list.title}</h2>
              <span className={`statut ${list.statut === 1 ? "open" : list.statut === 2 ? "waiting" : "close"}`}>
                  {list.statut === 1 ? "Ouvert" : list.statut === 2 ? "En attente" : "Fermé"}
              </span>
              <span className='category'>{list.category.title}</span>
              <span className='date'>Créé <ReactTimeAgo date={new Date(list.created_at)} locale="fr-FR" /></span>
            </div>

            {/* Post author */}

            <div className='post-author'>
              <div className="post-item">
                {list.user && (
                  <>
                    <div className="author">
                      <Avatar name={list.user.name} />
                      <p className='pseudo'>{list.user.name}</p>
                    </div>
                    <div className="message">
                      <EditorShow data={list.message}/>
                    </div>
                  </>
                )}
              </div>
            </div>
            
            {/* Post messages */}

            <div className="messages">
              {list.messages && list.messages.map(message => (
                <div className="post-item">
                  <div className="author">
                    <Avatar name={message.user.name} />
                    <p className='pseudo'>{message.user.name}</p>
                  </div>
                  <div className="message">
                    {editID == message.id ? (
                      <>
                        <Editor
                          value={messageEdit}
                          placeholder={messageEdit}
                          onChange={this.handleChangeMessageEdit}
                          onSubmit={() => this.handleUpdate(message.id)}
                          cancel={() => this.handleEdit(null)}
                        />
                      </>
                    ) : (
                      <>
                      <div className='icons'>
                        {message.users_id == localStorage.getItem("id") ? (
                          <>
                            <i class="fa-solid fa-trash" onClick={() => this.handleDelete(message.id)}></i>
                            <i class="fa-solid fa-pen-to-square" onClick={() => this.handleEdit(message.id, message.message)}></i>
                          </>
                        ) : (
                          <i class="fa-solid fa-flag"></i>
                        )}
                      </div>
                      <EditorShow data={message.message}/>
                      </>
                    )}

                    <div className='date'>
                      <i class="fa-solid fa-hashtag">{i++}</i>
                      <small><ReactTimeAgo date={message.created_at} locale="fr-FR"/></small>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Post reply */}

            {localStorage.getItem("token") ? (
              list.statut == 2 ? (
                <>
                  <div className='reply'>
                    <Editor
                      value={message}
                      onChange={this.handleChangeMessage}
                      disabled={true}
                    />
                  </div>

                  {/* Connect */}
                  <Link>
                    <div className="connect">
                      Patientez qu'un membre de l'équipe réponde à votre ticket.
                    </div>
                  </Link>
                </>
              ) : list.statut == 1 ? (
                <div className='reply'>
                  <Editor
                    value={message}
                    onChange={this.handleChangeMessage}
                    onSubmit={this.handleSubmit}
                  />
                </div>
              ) : (
                <div className='reply'>
                  <Editor
                    value={message}
                    onChange={this.handleChangeMessage}
                    disabled={true}
                  />

                  {/* Connect */}
                  <Link>
                    <div className="connect">
                      Ce ticket a été fermé.
                    </div>
                  </Link>
                </div>
              )
            ) : (
              <div className='reply'>
                <Editor
                  value={message}
                  onChange={this.handleChangeMessage}
                  disabled={true}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
  
export default TicketShow;