import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const AddDatas = async ({ category, title, message }, callback) => {
  try {
    const { data } = await API(false).post(`/ticket`, {
      category: category,
      title: title,
      message: message,
    });

    if (callback) {
      callback(data);
    }

    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default AddDatas;