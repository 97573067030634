import axios from "axios";

const API = (redirect = true) => {
    let headers = {
        "Authorization": localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
        'Accept': 'application/json, image/*, application/pdf',
        // 'X-API-KEY': process.env.REACT_APP_API_KEY,
    };

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
        headers,
    });

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response) {
                const status = error.response.status;
                const message = error.response.data?.message || "";

                if (status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("player");
                    if (redirect) {
                        window.location.href = "/login";
                    }
                }

                if (status === 403 && message === "VPN detected") {
                    window.location.href = "/vpn";
                }
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

if (localStorage.getItem("token")) {
    API().post(`${process.env.REACT_APP_API_URL}/user/update-status`, {});
}

export default API;
