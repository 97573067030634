import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const GetDatas = async () => {
  try {
    const { data } = await API().get(`/forum/get`);
    return data;
  } catch (error) {
    handleApiError(error);
  }
};

export default GetDatas;
