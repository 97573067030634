import API from '../../utils/Axios';
import handleApiError from '../../utils/handleApiError';

const DelDatas = async (id) => {
    try {
        const { data } = await API().delete(`/ticket-message/${id}`, {});
        return data;
    } catch (error) {
        handleApiError(error);
    }
};

export default DelDatas;