import React, { Component } from 'react';
import '../assets/css/utils/not-found.css';

class Soon extends Component {

  render() {
    return (
      <>
        <div className="no-found main">
            <i class="fas fa-tools"></i>
            <h4>Désolé, cette page est encore en développement.</h4>
        </div>
      </>
    )
  }
}

export default Soon;
